import React from 'react';
import { Link, useParams } from 'react-router-dom';
import destinations from '../data/destinations'; 
import './Tours.css';

function Destination() {
  const { id } = useParams();
  const destination = destinations.find(dest => dest.id === parseInt(id));

  if (!destination) {
    return <div>Destination not found.</div>;
  }

  return (
    <div className="tours container">
      <h2 className="text-center my-5">{destination.name}</h2>
      <div className="row">
        {destination.packageList.map(tourPackage => (
          <div key={tourPackage.id} className="col-md-4 col-lg-4 mb-4">
            <div className="card tour-card">
              <img
                src={tourPackage.image}
                alt={tourPackage.name}
                className="card-img-top card-image"
              />
              <div className="card-body">
                    <h3 className="card-title">{tourPackage.name}</h3>
                    <Link to={`/package/${tourPackage.id}`} className="btn btn-success">
                   View Details
                    </Link>
                </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Destination;
