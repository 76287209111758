import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Tours from './pages/Tours';
import Contact from './pages/Contact';
import Destination from './pages/Destination';
import PackageDetails from './components/PackageDetails';
import BlogList from './components/BlogList';
import BlogDetailsPage from './components/BlogDetailsPage';



// import DestinationPackages from './components/DestinationPackages'; // New component for destination packages

import './App.css';

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/tours" element={<Tours />} />
      <Route path="/blogs" element={<BlogList />} />
      <Route path="/blogs/:id" element={<BlogDetailsPage />} /> {/* Fix here */}
      <Route path="/destinations/:id" element={<Destination />} />
      <Route path="/package/:packageId" element={<PackageDetails />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
      <Footer />
    </div>
  );
}

export default App;
