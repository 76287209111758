import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import blogs from '../data/blogs';
import './BlogList.css'; // Import the custom CSS file

const BlogList = () => {
  return (
    <Container>
      <h3 className="title"> Our Blogs </h3>
      <Row>

        {blogs.map((blog) => (
          <Col md={4} key={blog.id} className="mb-4">
            <Card className="animated-card">
              {/* Add the Card Image */}
              <Card.Body>
                <Card.Title className="title-blog">{blog.title}</Card.Title>
                <Card.Text>
                  {blog.description.substring(0, 100)}... {/* Display a preview of the content */}
                </Card.Text>
                <Link to={`/blogs/${blog.id}`}>
                  <Button variant="success">Read More</Button>
                </Link>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default BlogList;
