import React from 'react';
import './About.css';
import aboutImage from '../assets/img/18.jpeg'; 
import img3 from '../assets/img/16.jpeg'; 

function About() {
  return (
    <div className="about">
      {/* About Us Section */}
      <section className="about-section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h2>Who we are</h2>
            <p>
          African Uncut Safaris is dedicated to providing unforgettable experiences
          across Africa. Our team of expert guides and travel planners ensure you
          have a safe, enjoyable, and enriching journey. Based in Nairobi, Africanuncut Safaris was founded by passionate adventurers and wildlife enthusiasts with a mission to offer authentic safari experiences that connect you with the African wilderness.
        </p>
        <p>
          Our commitment extends beyond tours; we aim to educate and inspire guests about preserving Africa's wildlife and natural habitats. Through partnerships with local communities and conservation organizations, we support efforts to protect endangered species and promote sustainable tourism.
        </p>
        <p>
          Offering a range of safari options from luxury fly-in safaris to personalized tours, we cater to all preferences. Whether seeking adventure, a peaceful retreat, or cultural immersion, Africanuncut Safaris ensures a unique and memorable experience. Join us to explore Africa's majestic wildlife, vibrant cultures, and breathtaking landscapes.
        </p>
          </div>
          <div className="col-md-6">
            <img src={aboutImage} alt="About Us" className="img-fluid about-image" />
          </div>
        </div>
      </div>
      </section>
      <section className="about-section">
  <div className="container">
    <div className="row align-items-center">
      <div className="col-md-6">
        <img src={img3} alt="About Us" className="img-fluid about-image" />
      </div>
      <div className="col-md-6">
        <h4>Discover the Heart of Africa with African Uncut Safaris</h4>
        <p>
          We are dedicated to crafting extraordinary safari experiences that
          immerse travelers in the stunning beauty and rich cultures of Africa.
          Our mission is to nurture a profound connection between our guests and
          the wild landscapes, wildlife, and local communities, sparking a passion
          for conservation and sustainable travel.
        </p>
        <p>
          Our safari experiences are meticulously designed to reveal Africa's
          untamed beauty. Guided by highly experienced professionals, each journey
          is thoughtfully planned to balance exhilarating wildlife encounters,
          cultural interactions, and tranquil moments in nature. We aim to create
          custom itineraries tailored to individual preferences, ensuring every
          traveler's dreams become a reality.
        </p>
        <p>
          We recognize that every traveler is unique, and we take pride in providing
          personalized service. From the moment you contact us until your safe return
          home, our team is committed to making sure your safari experience surpasses
          expectations. We attend to every detail with care, ensuring your comfort,
          safety, and enjoyment throughout the journey.
        </p>
      </div>
    </div>
  </div>
</section>


         {/* Call to Action Section */}
      <section className="call-to-action mt-5 text-center">
      <h2>Ready for an Unforgettable Adventure?</h2>
      <p>Book your tour with us today and explore the wonders of Africa.</p>
      <a href="https://wa.me/254721731748?text=Hi, I got your WhatsApp information from your website." className="btn btn-success btn-lg" role="button">Send Whatsapp Message</a>
      </section>

</div>

  );
}

export default About;
