import img5 from '../assets/img/tsa.jpg';
import img1 from '../assets/img/up.jpg'; 
import im1 from '../assets/img/1.jpeg'; 
import im3 from '../assets/img/3.jpeg'; 
import im4 from '../assets/img/4.jpeg'; 
import im10 from '../assets/img/a.jpg'; 
import im7 from '../assets/img/7.jpeg'; 
import im9 from '../assets/img/20.jpeg'; 
import img9 from '../assets/img/17.jpeg';
import img2 from '../assets/img/16.jpeg';
import img3 from '../assets/img/mtkenya.jpg';
import img4 from '../assets/img/f.jpg';
import kilima from '../assets/img/hike.jpg';
import mount from '../assets/img/mount.jpg';
import img6 from '../assets/img/mount.jpg';
import marangu from '../assets/img/marangu.jpg'
import tsavos from '../assets/img/qwer.jpeg';
import tsavo from '../assets/img/tsavo.jpg';
import unsplash from '../assets/img/unsplash.jpg';
import ambo from '../assets/img/ambo.jpg';
import sunss from '../assets/img/sunss.jpg';
import samburu from '../assets/img/samburu.jpg';
import girsam from '../assets/img/girsam.jpg';
import wildbeest from '../assets/img/wildbeest.jpg';
import lewa from '../assets/img/lewa.jpg';
import naks from '../assets/img/naks.jpg';
import nakss from '../assets/img/images.jpeg';
import gir from '../assets/img/22.jpeg';
import ballon from '../assets/img/ballon.jpg';
import chettah from '../assets/img/c.jpg';
 import ncw from '../assets/img/ncw.jpeg';
import b from '../assets/img/b.jpg';
import wer from '../assets/img/wer.jpeg';
import wheel from '../assets/img/15.jpeg';
import nnp from '../assets/img/10.jpeg';
import hellsg from '../assets/img/hellsg.jpg';
import coffee from '../assets/img/coffee.jpg';
import teafarm from '../assets/img/teafarm.jpg';
import nert from '../assets/img/nert.jpeg';

import ele from '../assets/img/ele.jpg';



const destinations = [
  {
    id: 1,
    name: 'Masai Mara',
    image: im4,
    packages: 5,
    packageList: [
      {
        id: 1, 
        name: '3 days private safari', 
        description: 'Experience the wild in a private safari.', 
        price: 1200, 
        image: img5, 
        duration: '3 Days 2 Nights', 
        overview: 'Enjoy an intimate journey through the heart of the Masai Mara with a dedicated guide. Witness the Big Five, breathtaking sunsets, and the Great Migration in a serene, private setting away from the crowds.'
      },
      {
        id: 2, 
        name: '4 days luxury fly-in safari', 
        description: 'Luxury safari with fly-in experience.', 
        price: 3000, 
        image: im10, 
        overview: 'This exclusive fly-in safari offers a seamless blend of luxury and adventure. Soar over the African savannah and land directly in the Masai Mara, where your luxury tented camp awaits, offering an unrivaled safari experience.'
      },
      {
        id: 3, 
        name: '3 days private luxury tour', 
        description: 'Luxury tour with private guide.', 
        price: 2500, 
        image: im4, 
        overview: 'Indulge in the ultimate luxury with this private tour, designed for discerning travelers seeking a personalized and exclusive safari experience in the Masai Mara.'
      },
      {
        id: 4, 
        name: '4 days exclusive midrange private safari', 
        description: 'Exclusive midrange safari experience.', 
        price: 2000, 
        image: im9, 
        overview: 'Explore the Masai Mara with a balance of comfort and adventure on this midrange private safari. Stay in comfortable lodges and enjoy daily game drives in a private vehicle.'
      },
      {
        id: 5, 
        name: '3 days private safari-van edition', 
        description: 'Private safari in a safari-van.', 
        price: 1500, 
        image: im7, 
        overview: 'This budget-friendly safari allows you to explore the Masai Mara in a private safari-van, offering a personalized experience at an affordable price.'
      }
    ]
  },
  {
    id: 2,
    name: 'Mt Kenya',
    image: img1,
    packages: 4,
    packageList: [
      {
        id: 6, 
        name: '5 days Sirimon route', 
        description: 'Trek through Sirimon route.', 
        price: 900, 
        image: img1, 
        overview: 'Trek the scenic Sirimon route to the summit of Mt. Kenya. This route offers stunning views and diverse landscapes, perfect for adventurous trekkers.'
      },
      {
        id: 7, 
        name: '4 days Naro Moru route', 
        description: 'Experience the Naro Moru route.', 
        price: 800, 
        image: kilima, 
        overview: 'The Naro Moru route is the fastest way to reach the peaks of Mt. Kenya. This 4-day trek is ideal for those seeking a challenging yet rewarding experience.'
      },
      {
        id: 8, 
        name: '5 days Chogoria route', 
        description: 'Discover the Chogoria route.', 
        price: 950, 
        image: img4, 
        overview: 'The Chogoria route is known for its dramatic scenery and quieter trails. This 5-day trek provides a less-crowded path to the summit, with stunning vistas along the way.'
      },
      {
        id: 9, 
        name: '6 days Timau Route', 
        description: 'Trek through the Timau route.', 
        price: 1000, 
        image: img6, 
        overview: 'The Timau route is a lesser-known but equally beautiful path up Mt. Kenya. Over six days, you’ll experience the varied landscapes and the tranquility of the mountain.'
      }
    ]
  },
  {
    id: 3,
    name: 'Mt Kilimanjaro',
    image: im3,
    packages: 4,
    packageList: [
      {
        id: 10, 
        name: '6 days Machame route', 
        description: 'Trek through Machame route.', 
        price: 1200, 
        image: img3, 
        overview: 'The Machame route, also known as the "Whiskey Route," is the most popular path to the summit of Mt. Kilimanjaro. It offers a challenging climb with stunning views.'
      },
      {
        id: 11, 
        name: '6 days Marangu route', 
        description: 'Experience the Marangu route.', 
        price: 1100, 
        image: marangu, 
        overview: 'The Marangu route, often called the "Coca-Cola Route," is the only route with hut accommodations. This 6-day trek is perfect for those seeking a comfortable and scenic climb.'
      },
      {
        id: 12, 
        name: '7 days Leshomo route', 
        description: 'Discover the Leshomo route.', 
        price: 1300, 
        image: mount, 
        overview: 'The Leshomo route is a more remote and less-traveled path on Mt. Kilimanjaro. This 7-day trek offers a unique and challenging adventure with fewer crowds.'
      },
      {
        id: 13, 
        name: '6 days Rongai route', 
        description: 'Trek through the Rongai route.', 
        price: 1150, 
        image: im3, 
        overview: 'The Rongai route approaches Kilimanjaro from the north, offering a different perspective of the mountain. This 6-day trek is known for its gradual ascent and stunning landscapes.'
      }
    ]
  },
  {
    id: 4,
    name: 'Tsavo',
    image: img5,
    packages: 3,
    packageList: [
      {
        id: 14, 
        name: '3 days Tsavo East midrange safari', 
        description: 'Midrange safari in Tsavo East.', 
        price: 800, 
        image: tsavo, 
        overview: 'Explore the vast wilderness of Tsavo East on this midrange safari. Encounter large herds of elephants, diverse wildlife, and stunning landscapes over three days.'
      },
      {
        id: 15, 
        name: '5 days Tsavo East and West private tour', 
        description: 'Private tour of Tsavo East and West.', 
        price: 1500, 
        image: tsavos, 
        overview: 'Experience the best of both worlds with this private tour of Tsavo East and West. Over five days, immerse yourself in the rich biodiversity and rugged beauty of these iconic parks.'
      },
      {
        id: 16, 
        name: '3 days Tsavo West midrange tour', 
        description: 'Midrange tour of Tsavo West.', 
        price: 850, 
        image: sunss, 
        overview: 'Tsavo West offers a unique safari experience with its volcanic landscapes and diverse wildlife. This midrange tour takes you on an unforgettable journey through the park’s highlights.'
      }
    ]
  },
  {
    id: 5,
    name: 'Amboseli',
    image: im1,
    packages: 3,
    packageList: [
      {
        id: 17, 
        name: '3 days fly-in luxury safari', 
        description: 'Luxury fly-in safari in Amboseli.', 
        price: 1000, 
        image: ambo, 
        overview: 'This fly-in luxury safari offers an exclusive and effortless journey to Amboseli. Experience close encounters with elephants and enjoy stunning views of Mt. Kilimanjaro in style.'
      },
      {
        id: 18, 
        name: '3 days private budget tour', 
        description: 'Budget tour of Amboseli.', 
        price: 700, 
        image: girsam, 
        overview: 'Discover the wonders of Amboseli on this private budget tour. Perfect for those looking for an affordable yet memorable safari experience.'
      },
      {
        id: 19, 
        name: '2 days private midrange tour', 
        description: 'Midrange tour of Amboseli.', 
        price: 600, 
        image: unsplash, 
        overview: 'This 2-day midrange tour offers a quick but immersive experience in Amboseli. Enjoy game drives and stunning landscapes, all with the backdrop of Mt. Kilimanjaro.'
      }
    ]
  },
  {
    id: 6,
    name: 'Group Joining',
    image: im9,
    packages: 5,
    packageList: [
      {
        id: 20, 
        name: '3 days Masai Mara safari', 
        description: 'Group joining safari in Masai Mara.', 
        price: 500, 
        image: wildbeest, 
        overview: 'Join a group safari to the Masai Mara and share the adventure with like-minded travelers. Experience the thrill of the Mara’s wildlife and landscapes over three days.'
      },
      {
        id: 21, 
        name: '3 days Amboseli tour', 
        description: 'Group joining tour in Amboseli.', 
        price: 600, 
        image: ambo, 
        overview: 'Explore Amboseli with a group and witness the iconic elephants and stunning views of Mt. Kilimanjaro. This three-day tour offers a budget-friendly yet unforgettable experience.'
      },
      {
        id: 22, 
        name: '4 days Masai Mara and Lake Nakuru safari', 
        description: 'Group joining safari in Masai Mara and Lake Nakuru.', 
        price: 800, 
        image: naks, 
        overview: 'This 4-day group safari takes you through the Masai Mara and Lake Nakuru, offering diverse wildlife encounters and breathtaking scenery.'
      },
      {
        id: 23, 
        name: '6 days Masai Mara, Lake Nakuru and Amboseli tour', 
        description: 'Group joining tour in Masai Mara, Lake Nakuru, and Amboseli.', 
        price: 1200, 
        image: nakss, 
        overview: 'Join a group for a 6-day adventure through some of Kenyas most iconic parks. Experience the best of the Masai Mara, Lake Nakuru, and Amboseli on this budget-friendly tour.'
      },
      {
        id: 24, 
        name: '7 days Masai Mara, Lake Nakuru, Lake Naivasha and Amboseli safari', 
        description: 'Group joining safari in Masai Mara, Lake Nakuru, Lake Naivasha, and Amboseli.', 
        price: 1400, 
        image: img5, 
        overview: 'This 7-day group safari offers an extensive exploration of Kenyas top wildlife destinations, from the Masai Mara to Amboseli, with stops at Lake Nakuru and Lake Naivasha.'
      }
    ]
  },
  {
    id: 7,
    name: 'Northern Frontier',
    image: im3,
    packages: 4,
    packageList: [
      {
        id: 25, 
        name: '3 days luxury Samburu tour', 
        description: 'Luxury tour of Samburu.', 
        price: 900, 
        image: samburu, 
        overview: 'Explore the rugged beauty of Samburu on this luxury tour. Over three days, experience the unique wildlife and stunning landscapes of this remote frontier in comfort.'
      },
      {
        id: 26, 
        name: '3 days fly-in Samburu Safari', 
        description: 'Fly-in safari in Samburu.', 
        price: 1200, 
        image: lewa, 
        overview: 'This fly-in safari offers a quick and exclusive escape to Samburu. Enjoy the region’s wildlife and landscapes from the comfort of a luxury camp.'
      },
      {
        id: 27, 
        name: '5 days Samburu and Ol Pejeta safari', 
        description: 'Safari in Samburu and Ol Pejeta.', 
        price: 1500, 
        image: ele, 
        overview: 'Combine the unique wildlife of Samburu with the conservation efforts of Ol Pejeta on this 5-day safari. Experience the best of Kenya’s northern frontier and central highlands.'
      },
      {
        id: 28, 
        name: '6 days Samburu, Aberdares and Sweetwaters midrange tour', 
        description: 'Midrange tour of Samburu, Aberdares, and Sweetwaters.', 
        price: 1800, 
        image: img5, 
        overview: 'This 6-day tour offers a comprehensive journey through some of Kenya’s most diverse landscapes, from the arid beauty of Samburu to the lush forests of the Aberdares and the wildlife haven of Sweetwaters.'
      }
    ]
  },
  {
    id: 8,
    name: 'Kenya, Tanzania and Zanzibar combo',
    image: img9,
    packages: 6,
    packageList: [
      {
        id: 29, 
        name: '6 days Kenya and Tanzania fly-in migration safari', 
        description: 'Fly-in migration safari in Kenya and Tanzania.', 
        price: 2500, 
        image: gir, 
        overview: 'Witness the Great Migration from both sides of the border on this fly-in safari. Explore the Masai Mara and Serengeti in style, with the added convenience of air travel.'
      },
      {
        id: 30, 
        name: '10 days luxury Kenya and Tanzania tour', 
        description: 'Luxury tour of Kenya and Tanzania.', 
        price: 5000, 
        image: ballon, 
        overview: 'This 10-day luxury tour takes you through the best of Kenya and Tanzania, offering exclusive accommodations and unparalleled wildlife experiences in the Masai Mara, Serengeti, and Ngorongoro Crater.'
      },
      {
        id: 31, 
        name: '9 days Kenya and Tanzania midrange edition', 
        description: 'Midrange tour of Kenya and Tanzania.', 
        price: 4000, 
        image: chettah, 
        overview: 'Experience the highlights of Kenya and Tanzania on this midrange tour. Over 9 days, explore the Masai Mara, Serengeti, and Ngorongoro Crater, staying in comfortable lodges and camps.'
      },
      {
        id: 32, 
        name: '15 days Ultimate Tour-Kenya and Tanzania', 
        description: 'Ultimate tour of Kenya and Tanzania.', 
        price: 7000, 
        image: b, 
        overview: 'This 15-day ultimate tour offers an in-depth exploration of Kenya and Tanzania’s top wildlife destinations. From the Masai Mara to the Serengeti and Zanzibar, this tour provides the adventure of a lifetime.'
      },
      {
        id: 33, 
        name: '12 days Kenya, Tanzania and Zanzibar safari', 
        description: 'Safari in Kenya, Tanzania, and Zanzibar.', 
        price: 6000, 
        image: wer, 
        overview: 'This 12-day safari takes you through the best of East Africa, from the savannas of Kenya and Tanzania to the beaches of Zanzibar. Perfect for those seeking a blend of adventure and relaxation.'
      },
      {
        id: 34, 
        name: '6 days Zanzibar Island Experience', 
        description: 'Experience Zanzibar Island.', 
        price: 2000, 
        image: wheel, 
        overview: 'This 6-day Zanzibar Island Experience offers a perfect tropical getaway. Explore the rich culture, pristine beaches, and vibrant marine life of this beautiful island.'
      }
    ]
  },
  {
    id: 9,
    name: 'Day Tours and Excursions',
    image: img2,
    packages: 6,
    packageList: [
      {
        id: 35, 
        name: 'Full day exclusive Nairobi Excursion', 
        description: 'Exclusive excursion in Nairobi.', 
        price: 200, 
        image: nnp, 
        overview: 'Explore the vibrant city of Nairobi on this full-day excursion. Visit iconic landmarks, experience the local culture, and enjoy a taste of the city’s unique charm.'
      },
      {
        id: 36, 
        name: 'Coffee Farm tour', 
        description: 'Tour of a coffee farm.', 
        price: 150, 
        image: coffee, 
        overview: 'This coffee farm tour offers a fascinating insight into Kenyas coffee industry. Learn about the coffee-making process from bean to cup, and enjoy a tasting session.'
      },
      {
        id: 37, 
        name: 'Tea farm tour', 
        description: 'Tour of a tea farm.', 
        price: 150, 
        image: teafarm, 
        overview: 'Discover the art of tea production on this guided tour of a Kenyan tea farm. Learn about the cultivation and processing of tea, and enjoy a sampling of different varieties.'
      },
      {
        id: 38, 
        name: 'Lake Naivasha and Hell\'s Gate combo', 
        description: 'Combo tour of Lake Naivasha and Hell\'s Gate.', 
        price: 300, 
        image: hellsg, 
        overview: 'This combo tour takes you to the serene Lake Naivasha and the dramatic landscapes of Hell’s Gate. Enjoy boat rides, wildlife sightings, and a guided walk through the park.'
      },
      {
        id: 39, 
        name: 'Nanyuki Equator road trip', 
        description: 'Road trip to Nanyuki Equator.', 
        price: 250, 
        image: nert, 
        overview: 'Embark on a scenic road trip to Nanyuki, where you’ll visit the Equator and explore the surrounding attractions. This day trip offers a mix of adventure and education.'
      },
      {
        id: 40, 
        name: 'Nairobi City walking tour', 
        description: 'Walking tour of Nairobi City.', 
        price: 100, 
        image: ncw, 
        overview: 'Discover the hidden gems of Nairobi on this walking tour. Explore the city’s history, culture, and architecture with a knowledgeable guide leading the way.'
      }
    ]
  }
     

];
  
export default destinations;
