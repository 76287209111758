import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import destinations from '../data/destinations'; // Assuming this is where your destinations are stored
import emailjs from 'emailjs-com'; // Import EmailJS
import './PackageDetails.css';

function PackageDetails() {
  const { packageId } = useParams();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    date: '',
    package: ''
  });
  let selectedPackage = null;

  // Find the package in any destination
  destinations.forEach(destination => {
    const foundPackage = destination.packageList.find(pkg => pkg.id === parseInt(packageId));
    if (foundPackage) {
      selectedPackage = foundPackage;
    }
  });

  useEffect(() => {
    if (selectedPackage) {
      setFormData((prevData) => ({ ...prevData, package: selectedPackage.name }));
    }
  }, [selectedPackage]);

  if (!selectedPackage) {
    return <div className="package-not-found">Package not found.</div>;
  }

  // Update form data state
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const emailParams = {
      to_name: 'African Uncut Safaris Team',
      from_name: formData.name,
      from_email: formData.email,
      from_phone: formData.phone,
      package_name: formData.package,
      tour_date: formData.date,
      message: `A new booking request for the ${formData.package} package.`
    };

    emailjs.send('service_rtxogli', 'YOUR_TEMPLATE_ID', emailParams, 'YOUR_USER_ID')
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      alert('Your booking request has been sent successfully!');
    }, (error) => {
      console.error('FAILED...', error);
      alert('Failed to send booking request.');
    });
    // Clear the form after submission
    setFormData({
      name: '',
      email: '',
      phone: '',
      date: '',
      package: selectedPackage.name
    });
  };

  return (
    <div className="package-details">
      {/* Image Banner */}
      <div className="package-banner" style={{ backgroundImage: `url(${selectedPackage.image})` }}>
        <h1 className="package-title">{selectedPackage.name}</h1>
      </div>

      <div className="package-content container">
        {/* Tour Overview */}
        <section className="package-overview">
          <h2>Overview</h2>
          <p>{selectedPackage.overview}</p>
          <p>
            Duration: <strong>{selectedPackage.duration}</strong>
          </p>
          <p className="package-price">
            Total Cost Per Person: <strong> ${selectedPackage.price}</strong>
          </p>
        </section>

        {/* Inclusions and Exclusions */}
        <section className="package-details-sections">
          <div className="inclusions">
            <h3>Inclusions</h3>
            <p>Accommodation</p>
            <p>Park fees</p>
            <p>Daily meals (breakfast, lunch, dinner)</p>
            <p>Game drives in a 4x4 safari vehicle</p>
            <p>Airport transfers</p>
            <p>Guided tours and activities</p>
            <p>Drinking water during the safari</p>
            <p>All government taxes and levies</p>
          </div>
          <div className="exclusions">
            <h3>Exclusions</h3>
            <p>International flights</p>
            <p>Visa fees</p>
            <p>Travel insurance</p>
            <p>Personal expenses (e.g., souvenirs, laundry)</p>
            <p>Alcoholic and non-alcoholic beverages</p>
            <p>Tips for guides and drivers</p>
          </div>
        </section>

        {/* Booking Form */}
        <section className="package-booking">
          <h3>Book This Tour</h3>
          <form className="booking-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="package">Package Name</label>
              <input type="text" id="package" name="package" value={formData.package} readOnly />
            </div>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone</label>
              <input type="text" id="phone" name="phone" value={formData.phone} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="date">Preferred Tour Date</label>
              <input type="date" id="date" name="date" value={formData.date} onChange={handleChange} required />
            </div>
            <button type="submit" className="submit-button">Book Now</button>
          </form>
        </section>
      </div>
    </div>
  );
}

export default PackageDetails;
