import React, { useState } from 'react';
import './Tours.css';
import { Link } from 'react-router-dom';
import destinations from '../data/destinations';

function Tours() {
  const [searchTerm, setSearchTerm] = useState('');
  const [maxPrice, setMaxPrice] = useState('');

  // Flattening the package list across all destinations
  const allPackages = destinations.flatMap(destination => 
    destination.packageList.filter(tourPackage =>
      (maxPrice === '' || tourPackage.price <= maxPrice) &&
      tourPackage.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

 

  const handlePriceChange = (e) => {
    setMaxPrice(e.target.value);
  };

  return (
    <div className="tours-container container-fluid">
      <div className="row">
        <div className="col-md-2 sidebar">
          <h3>Filter Tours</h3>
          <div className="filter-group">
            <label htmlFor="search">Search</label>
            <input
              type="text"
              id="search"
              className="form-control"
              placeholder="Search by name"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
 
          <div className="filter-group">
            <label htmlFor="price">Max Price</label>
            <input
              type="number"
              id="price"
              className="form-control"
              placeholder="Enter max price"
              value={maxPrice}
              onChange={handlePriceChange}
            />
          </div>
        </div>
        <div className="col-md-10">
          <h2 className="text-center my-5">Our Tours</h2>
          <div className="row">
            {allPackages.map(tourPackage => (
              <div key={tourPackage.id} className="col-md-4 col-lg-3 mb-4">
                <div className="card tour-card">
                  <img
                    src={tourPackage.image}
                    alt={tourPackage.name}
                    className="card-img-top card-image"
                  />
                  <div className="card-body">
                    <h3 className="card-title">{tourPackage.name}</h3>
                    <p className="card-text">Price: ${tourPackage.price}</p>
                    <Link to={`/package/${tourPackage.id}`} className="btn btn-success">
                      View Details
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tours;
