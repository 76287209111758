import React from 'react';
import { useParams } from 'react-router-dom';
import blogs from '../data/blogs';
import './BlogDetailsPage.css';

const BlogDetailsPage = () => {
  const { id } = useParams();
  const blog = blogs.find(blog => blog.id === parseInt(id));

  if (!blog) {
    return <h2>Blog not found</h2>;
  }

  // Function to parse content into an array of strings for list items
  const parseContentToList = (content) => {
    return content.trim().split('\n').filter(item => item.trim() !== '');
  };

  const contentList = parseContentToList(blog.content);

  return (
    <div className="blog-details-page">
      <h1 className="blog-title">{blog.title}</h1>
      <p className="blog-meta">
        <strong>By:</strong> {blog.author} | <strong>Date:</strong> {blog.date}
      </p>
  
      <div className="blog-content">
        <p> {blog.description} </p> 
        <ol>
          {contentList.map((item, index) => (
            <li key={index} dangerouslySetInnerHTML={{ __html: item }} />
          ))}
        </ol>
        <p> {blog.summary} </p> 

      </div>
      <div className="tags">
        {blog.tags && blog.tags.map((tag, index) => (
          <span key={index} className="tag">{tag}</span>
        ))}
      </div>
    </div>
  );
};

export default BlogDetailsPage;
