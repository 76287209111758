import React from 'react';

const CenterDiv = () => {
  return (
    <div style={{ display: 'block', textAlign: 'center', padding: '0 5px 5px 5px' }}>
      <span className="e64bb58969b14">&nbsp;</span>
      <a href="https://www.safaribookings.com/p5896" rel="nofollow">
       
      </a>
    </div>
  );
};

export default CenterDiv;
